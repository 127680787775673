import React from 'react';
import { Col, Container } from 'reactstrap';
import { PREFIX } from 'components/core/constant';
import HcLink from 'components/core/layout/link';
import HcText from 'components/core/layout/text';
import StaticPageLogo from 'components/logo';
import Link from 'next/link';

const CreateAccountLink = () => {
  return (
    <div>
      <HcText size="medium" as={'span'} style={{ fontWeight: '600' }}>
        New user?
      </HcText>
      <HcLink size="medium" as={Link} href={`/sign-up`}>
        <HcText
          size="medium"
          as={'u'}
          className="ps-1"
          style={{ fontWeight: '600' }}
        >
          Create an account
        </HcText>
      </HcLink>
    </div>
  );
};

const SignInLink = () => {
  return (
    <div>
      <HcText size="medium" as={'span'} style={{ fontWeight: '600' }}>
        Already User?
      </HcText>
      <HcLink size="medium" as={Link} href={`/sign-in`}>
        <HcText
          size="medium"
          as={'u'}
          className="ps-1"
          style={{ fontWeight: '600' }}
        >
          Sign In
        </HcText>
      </HcLink>
    </div>
  );
};

const LandingHeader = ({ isCreateAccount = false, isSignIn = false }) => {
  return (
    <header className={`${PREFIX}__landing__header`}>
      <Container fluid className="px-0">
        <Col className="d-flex justify-content-between align-items-center flex-wrap">
          <div className="text-center text-md-start">
            <StaticPageLogo />
          </div>
          {isCreateAccount && <CreateAccountLink />}
          {isSignIn && <SignInLink />}
        </Col>
      </Container>
    </header>
  );
};

export default LandingHeader;
