import { Button } from 'components/core/button';
import Heading from 'components/core/layout/heading';
import HcText from 'components/core/layout/text';
import LandingHeader from 'components/layout/landing-header';
import Head from 'next/head';
import Image from "next/legacy/image";
import { useRouter } from 'next/router';

import { Account } from '../store/account';

export default function FourOhFour() {
  const router = useRouter();

  const isLoggedIn = JSON.parse(Account.User.get()) !== null;

  const onRedirect = () => {
    if (isLoggedIn) {
      router.push('/workspace');
    } else {
      router.push('/');
    }
  };

  return (
      <>
        <Head>
          <title>404 Error Page | Not Found</title>
        </Head>
        <LandingHeader />

        <div className="error-404-page">
          <Heading as="h2" size="2xl">
            Error
          </Heading>
          <HcText size="xlarge" as={'p'} style={{ fontWeight: '500' }}>
            Sorry, page not found
          </HcText>

          <div className="error-404-page-img">
            <Image src="/assets/images/404.svg" width={384} height={319} />
          </div>

          <Button
              onClick={onRedirect}
              size="lg"
              variant="primary"
              className="error-redirect-btn"
          >
            Go to {isLoggedIn ? 'Workspace' : 'Homepage'}
          </Button>
        </div>
      </>
  );
}

